
@import "fonts"; // deve stare prima di bootstrap
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~@fortawesome/fontawesome-free/scss/brands";
@import "~@fortawesome/fontawesome-free/scss/regular";

@import "bootstrap-rewrite-variables"; // deve stare prima di bootstrap
@import "~bootstrap/scss/bootstrap"; // bootstrap in node_modules
@import "~@glidejs/glide/dist/css/glide.core.css";
@import "~@glidejs/glide/dist/css/glide.theme.css";

@import "layout";
@import "glide"; // rewrite plugin carousel Glide

@import "general";
@import "welcome";
@import "pages/farmacia";
