// GENERIC & UTILITIES

body {

    .master_container{
        border-top: 10px solid #9a9a9a;
    }

}

[data-bs-toggle] {
	cursor: pointer;
}

[role="button"] {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.flex-grow-1 { flex-grow: 1; }
.flex-grow-0 { flex-grow: 0; }
.flex-shrink-1 { flex-shrink: 1; }
.flex-shrink-0 { flex-shrink: 0; }

// Colore bottone personalizzato con le funzioni bootstrap
.btn-default {
	@include button-variant($white,$card-border-color);
	@include button-outline-variant(
		$gray-700,
		$color-hover: $gray-700,
  	$active-background: $light,
		$active-border: $card-border-color,
	  $active-color: $gray-700,
	);
	border-color: $card-border-color;
	&.is-active {
		color: $link-color;
	}
	&:focus {
    border-color: $input-focus-border-color;
    outline: 0;
    box-shadow: $input-btn-focus-box-shadow;
  }
}

.btn-link,
.btn-link:focus {
	font-size: $font-size-base;
	border: 0;
	outline: 0;
	box-shadow: none;
}

// distanzia bottoni contigui
.btn + .btn {
	margin-left: 0.5rem !important;
}
.btn.btn-sm + .btn.btn-sm {
	margin-left: 0.25rem !important;
}

// icone in bottone
.btn i[class*=fa-] {
	display: inline-block;
	font-size: 1.2rem;
	transform: translate(0,0.1rem);
	width: 1.2rem;
	height: 0.5em;
	line-height: 0.5em;
}

.input-group > .input-group-text {
	z-index: 1;
	font-weight: bold;
	&:first-child {
		border-right: none;
		padding-right: 5px;
	}
	&:last-child {
		border-left: none;
		padding-left: 5px;
	}
}

textarea.form-control.invisible-textarea {
	min-height: 7rem;
	border: 0 !important;
	border-radius: 0 !important;
	outline: 0 !important;
	box-shadow: none !important;
	padding: 0 calc( $input-padding-x / 2 ) !important;
}

.form-control.input-outline-light {
	color: color-contrast($primary);
	border-color: color-contrast($primary);
	background-color: transparent;
	&::placeholder {
		color: transparentize( color-contrast($primary), 0.5 );
	}
}

.btn-border-radius-alt,
.input-border-radius-alt {
	border-radius: 0.3rem;
}

// LAYOUT

.main-layout {
	.divisory-line {
		@include media-breakpoint-up(md) {
			position: relative;
			padding-left: 30px;
			&:before,
			&:after {
				position: absolute;
				top:0;
				left:0;
				display: block;
				content:'';
				width: 30px;
				height: 450px;
				max-height: 100vh;
			}
			&:before {
				background: #c0c0c0;
				border-left: 10px solid #9a9a9a;
				border-right: 10px solid #e0e0e0;
				z-index: 1;
			}
			&:after {
				z-index: 2;
				background:
				linear-gradient(to top, rgba(255, 255, 255, 1) 0%,
																rgba(255, 255, 255, 1) 5%,
																rgba(255, 255, 255, 0) 100%);
				background-position: bottom left;
				background-repeat: no-repeat;
				background-size: 100% 60px;
			}
		}
	}
	.main-body {
		float: right;
		clear: both;
		padding: 2rem 0 2rem 0;
		width: 100%;
		@include media-breakpoint-up(md) {
			padding: 78px 0 2rem 2rem;
		}
		@include media-breakpoint-up(xl) {
			// max-width: 880px; // --> inserire per essere fedeli al template grafico.
			padding-left: 3rem;
		}
	}
	.main-sidebar {
		padding-right: 1rem;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: flex-start;
		.sidebar-nav {
			@include media-breakpoint-up(md) {
				margin-top: 85px;
			}
			.hamburger-input {
				display: none;
			}
			.hamburger-label {
				display: none;
			}
			ul.main-menu {
				display: block;
				margin:0;
				padding:0;
				list-style: none;
				font-size: $font-size-sm;
				li {
					padding:0;
					margin:0 0 1.5rem 0;
					a {
						text-transform: uppercase;
						display: inline-block;
						text-decoration: none;
						color: $body-color;
						display: flex;
						align-items: center;
						justify-content: flex-start;
						&:hover {
							color: $link-color;
						}
						i {
							font-size: 1.5em;
							margin-right: 0.5rem;
						}
					}
					&.is-active a {
						color: $link-color;
						text-decoration: underline;
					}
					&.with-icon a {
						text-transform: none;
						text-decoration: none;
					}
				}
			}
		}
		// Menu in mobile - hamburger
		@include media-breakpoint-down(md) {
			flex-direction: row;
			align-items: flex-end;
			padding-right: 0;
			.sidebar-nav {
			.hamburger-label {
				display: block;
				color: $secondary;
				width: 2rem;
				height: 2rem;
				position: relative;
				i {
					font-size: 1.5rem;
					position: absolute;
					top:0.25rem;
					right:0.25rem;
				}
			}
			ul.main-menu {
				z-index: $zindex-fixed;
				position: fixed;
				top:0;
				left:0;
				padding: 2rem 1rem;
				overflow: auto;
				max-width: 350px;
				width: calc(100vw - 5rem );
				height: 100vh;
				background: $navbar-dark-active-color;
				box-shadow: $box-shadow;
				transition: $transition-base;
			}
			.hamburger-input:not(:checked) ~ ul.main-menu {
				left: calc( -1 * ( 100vw + 1rem ) );
			}
			.hamburger-input:checked ~ .hamburger-label {
				color: $primary;
			}
		}
	}


	}
}

// ELEMENTS

.logo {
	display: block;
	width: auto;
	max-width: 100%;
	height: 2rem;
	margin: 29px 0 0 0;
	img {
		width: auto;
		height: 100%;
		object-fit: contain;
		object-position: left center;
	}
}

.default-picture {
	position:relative;
	margin:0;
	//background-color: $primary;
	color: color-contrast($primary);
	i {
		font-size: 1rem;
		display: flex;
		align-items:center;
		justify-content:center;
	}
	img {
		position:absolute;
		top:0;
		left:0;
		width: 100%;
		height:100%;
		object-fit: cover;
		object-position: center;
	}
}

.form-search {
	position:relative;
	width: 100%;
	max-width: 300px;
	input[type="search"],
	input[type="text"] {
		padding-right: calc( ( $input-btn-padding-x * 2 ) + ( $input-btn-border-width * 2 ) + $input-btn-font-size )
	}
	input[type="search"]::-webkit-search-cancel-button {
		display: none;
	}
	button,
	.btn
	input[type="submit"],
	input[type="button"] {
		position:absolute;
		top:0;
		right:0;
		z-index:1;
		padding: 0;
		width: calc( ( $input-btn-padding-x * 2 ) + 1rem );
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}



// CARD CON TOOLS IN HEADER

@mixin dataCheck_toggle($name){
	[data-check="#{$name}"] {
		&:checked ~ [data-hide="#{$name}"],
		&:checked ~ * [data-hide="#{$name}"],
		&:not(:checked) ~ [data-show="#{$name}"] ,
		&:not(:checked) ~ * [data-show="#{$name}"] {
			display: none;
		}
	}
}

.tool-card {

	.icon-btn {
		display: block;
		color: $secondary;
		position: absolute;
		top: 1rem;
		i {
			font-size: 1rem;
			width: 1em;
			height: 1em;
			line-height: 1em;
		}
		&.first-right {
			right:1rem;
		}
		&.second-right {
			right:3rem;
		}
		&.third-right {
			right:5rem;
		}
		&.first-left {
			left:1rem;
		}
	}

	[data-check] {
		display: none;
	}
	@include dataCheck_toggle('starred');
	@include dataCheck_toggle('detail');
	@include dataCheck_toggle('selected');

	[data-check="selected"]:checked ~ * .head {
		color: $primary;
	}

	.head {
		display:flex;
		justify-content: flex-start;
		align-items: flex-start;
		.title {
			display: inline-block;
			margin-right: 0.5rem;
			word-break: break-all;
		}
		.date {
			display: inline-block;
			white-space: nowrap;
		}
		@include media-breakpoint-down(sm) {
			flex-direction: column;
		}
	}

	[data-check="detail"]:checked ~ * .head {
		flex-direction: column;
	}

}
