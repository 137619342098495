// modifiche al plugin GLIDE carousel

.glide__slides {
	margin: 0;
}

.glide__arrow {
	border: none;
	background: none;
	border-radius: 50%;
	box-shadow: none;
	font-size: 2rem;
	text-shadow: 0 0 0.4rem rgb(0 0 0 / 40%), 0 0 1.5rem rgb(0 0 0 / 40%);
	cursor: pointer;
	&--left {
    left: 0;
	}
	&--right {
		right: 0;
	}
}

.glide-size-small {
	.glide__arrow {
		font-size: 1.5rem;
	}
}

// caroselli prodotti 

#modal_carousels {
	visibility: hidden;
	position: fixed;
	top: 0;
	left: 0;
	.glide {
		max-width: 800px;
	}
}
.modal #modal_carousels {
	visibility: visible;
	position: relative;
	top:auto;
	left:auto;
}

// slide attiva con bordo 
// .modal #modal_carousels .prodotto_thumbnails .glide__slide--active {
// 	border: $primary solid 3px;
// }