
.alert-danger{
    font-size: 10pt;
    text-align: left;

    ul{
        margin: 0;
        list-style: none;
    }
}

.image_preview_cnt{
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 10px;

    .image_preview{
        width: 100px;
        height: 100px;
    }

}

.items_with_image_list{

    .item_with_image{
        padding: 1rem;
        width: 300px;
        margin-right: 20px;
        margin-bottom: 50px;
    }

}

.glide__slide{
    img{
        width: 316px;
        height: 316px;
        object-fit: cover;
    }
}
