.welcome_cloud{

    margin-left: auto;
    margin-right: auto;
    width: 250px;
    margin-top: calc(100vh / 2 - 100px);

    .main_logo{
        width: 100%;
    }
    .label{
        margin-top: 20px;
        text-align: center;
    }

    .accedi_btn{
        display: block;
        margin-left: auto;
        margin-right: auto;
        border: 1px solid $primary;
        border-radius: 0.3rem;
        color: $primary !important;
    }
}
